// tabs
.tab-container {
  .tabs {
    align-items: center;
    border: rem-calc(4) solid $primary-color;
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    &:before,
    &:after {
      display: table;
      content: " ";
      flex-basis: 0;
      order: 1;
    }

    .tabs-title {
      &:first-of-type {
        a {
          margin-left: 0;
        }
      }
      &:last-of-type {
        a {
          margin-right: 0;
        }
      }
      position: relative;
      text-align: center;
      width: 100%;
      a {
        background-color: $white;        
        color: $black;
        display: block;
        font-weight: 400;
        margin: 0;
        padding: rem-calc(16) rem-calc(20);
        text-decoration: none;
        text-transform: uppercase;
        @include breakpoint(small) {
          font-size: rem-calc(18);
          line-height: rem-calc(18);
          padding: rem-calc(16) rem-calc(10);
        }
        @include breakpoint(medium) {
          font-size: rem-calc(32);
          line-height: rem-calc(32);
          padding: rem-calc(16) rem-calc(20);
        }

        &:focus {
          box-shadow: none !important;
        }

      }
      &.is-active {
        &:before {
          background-color: $primary-color;
          content:'';
          position: absolute;
          top:-50%;
          left:0;
          width: 100%;
          height: 200%;
          z-index: -1;
        }
        a {
          background-color: $primary-color;
          color: $white;
          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .tabs-content {
    background: $white;
    border-top: 0;
    position: relative;    
    .tabs-panel {
      display: none;
      overflow: hidden;
      padding: 0;
      position: relative;
      
      .img-map {
        max-width: rem-calc(740);
        padding: rem-calc(48) 0;
        width: 100%;
      }

      .tab-accordion-title {
        background: $white;
        border: rem-calc(2) solid $black;
        color: $charcol;
        cursor: pointer;
        font-weight: bold;
        padding: rem-calc(10);
        position: relative;
        width: 100%;
        z-index: 1;
        &.is-active {
          background: $charcol;
          color: $white;
        }
        @include breakpoint(small) {
          display: block;
        }
        @include breakpoint(large) {
          display: none;
        }
      }

      .tab-main-content {
        @include breakpoint(small) {
          display: none;
        }
        @include breakpoint(large) {
          display: block;
        }
        .content-block {
          @include breakpoint(large) {
            padding: rem-calc(16) 0;
          }
          .location-list {
            .loc-list-item {
              display: flex;
              padding: rem-calc(16) rem-calc(24);
              &:nth-of-type(odd) {
                background-color: $primary-color;
                color: $white;
                a {
                  color: $white;
                }
              }
              &:nth-of-type(even) {
                background-color: $white;
                color: $black;
                a {
                  color: $black;
                }
              }
              a {
                text-decoration: underline;
              }
            }

          }

        }
      }

      .private-divider {
        border-bottom: rem-calc(2) solid $primary-color;
        margin: rem-calc(48) 0;
        width: 100%;
      }

      &.is-active {
        display: block;
        .tab-main-content {
          display: block;
        }
      }
      .icon {
        margin-right: rem-calc(20);
      }
    }
  }
  &.ele-shadow {
    .tabs-content {
      box-shadow: 0 rem-calc(2) rem-calc(10) rgba(0, 0, 0, 0.2);
    }
  }
}