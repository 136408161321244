// colours
.b-c-primary {
  background-color: $primary-color;
}
.b-c-secondary {
  background-color: $secondary-color;
}
.b-c-white {
  background-color: $white;
}
.b-c-light-gray {
  background-color: $light-gray;
}
.b-c-medium-gray {
  background-color: $medium-gray;
}
.b-c-dark-gray {
  background-color: $dark-gray;
}
.b-c-charcol {
  background-color: $charcol;
}
.b-c-black {
  background-color: $black;
}
.f-c-white {
  color: $white;
}
.f-c-primary {
  color: $primary-color;
}


// responsive-embed
.responsive-embed {
  margin-bottom: 0;
  padding-bottom: 58%;
  &.vertical-embed {
    max-width: 56.666%;
    padding-bottom: 100%;
  }
}
