// buttons
.btn {
	background-color: $dark-gray;
	border: rem-calc(2) solid $dark-gray;
	border-radius: rem-calc(32);
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;	
	max-width: rem-calc(220);
	padding: rem-calc(12) rem-calc(80) rem-calc(12) rem-calc(30);
	position: relative;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(17);
	}	
	.arrow {
		@include vertical-center();
		background-image: url('/assets/images/icon-arrow-white.svg');
		background-position: center;
		background-repeat: no-repeat;	
		background-size: contain;	
		height: rem-calc(19);
		transition: all 0.25s ease;
		right: rem-calc(24);
		width: rem-calc(31);
	}

	&:hover {
		background-color: $white;
		border-color: $dark-gray;
		color: $dark-gray;	
		.arrow {
			background-image: url('/assets/images/icon-arrow-black.svg');
		}	
	}
	&:focus {
		.arrow {
			background-image: url('/assets/images/icon-arrow-black.svg');
		}	
	}
}

.btn-role {
	background-color: $role-color;
	border-color: $black;
	color: $black;
	.arrow {
		background-image: url('/assets/images/icon-arrow-black.svg');
	}
}

.btn-paths {
	background-color: $white;
	border: rem-calc(2) solid $medium-gray;
	border-radius: rem-calc(32);
	color: $secondary-color;
	max-width: rem-calc(178);
	padding: rem-calc(9) rem-calc(16);
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(12);
		line-height: rem-calc(18);
	}
	&:hover {
		background-color: $secondary-color;
		border-color: $secondary-color;
		color: $white;
	}	
}

.btn-close-modal {
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;	
	max-width: rem-calc(370);
	padding: rem-calc(20) rem-calc(72) rem-calc(20) rem-calc(20);
	position: relative;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(17);
	}	
	&:after {
		@include vertical-center();
		background-image: url('/assets/images/icon-close-white.svg');
		background-position: center;
		background-repeat: no-repeat;	
		background-size: contain;	
		content:'';
		display: block;
		height: rem-calc(26);
		transition: all 0.25s ease;
		right: rem-calc(24);
		width: rem-calc(23);
	}
	&:focus {
		&:after {
			background-image: url('/assets/images/icon-close-black.svg');
		}	
	}
}

.btn-accept {
	background-color: $white;
	border: rem-calc(2) solid $black;
	border-radius: rem-calc(32);
	color: $black;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;	
	max-width: rem-calc(370);
	padding: rem-calc(20) rem-calc(72) rem-calc(20) rem-calc(20);
	position: relative;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(17);
	}	
	.arrow {
		@include vertical-center();
		background-image: url('/assets/images/icon-arrow-black.svg');
		background-position: center;
		background-repeat: no-repeat;	
		background-size: contain;	
		height: rem-calc(19);
		transition: all 0.25s ease;
		right: rem-calc(24);
		width: rem-calc(31);
	}

	&:hover {
		background-color: $white;
		border-color: $dark-gray;
		color: $dark-gray;	
		.arrow {
			background-image: url('/assets/images/icon-arrow-black.svg');
		}	
	}
	&:focus {
		.arrow {
			background-image: url('/assets/images/icon-arrow-black.svg');
		}	
	}
}
.btn-cancel {
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-weight: bold;	
	max-width: rem-calc(370);
	padding: rem-calc(20);
	position: relative;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	transition: all 0.25s ease;
	@include breakpoint(small){
		font-size: rem-calc(16);
		line-height: rem-calc(16);
	}
	@include breakpoint(medium){
		font-size: rem-calc(16);
		line-height: rem-calc(17);
	}
}

.btn-play {
	background-image: url('/assets/images/btn-play-white.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;	
	cursor: pointer;	

	&:focus {
		background-image: url('/assets/images/btn-play-black.svg');
	}
	@include breakpoint(small){
		height: rem-calc(70);
		width: rem-calc(70);
	}
	@include breakpoint(medium){
		height: rem-calc(107);
		width: rem-calc(107);
	}
}
