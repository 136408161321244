.title-bg {
  background-color: $primary-color;
  height: 100%;
  width: 100%;
  position: relative;
  .title-bg-left, .title-bg-right {
    background-size: contain;
    background-repeat: repeat-x;
    height: 100%;
    position: absolute;
    width: 50vw;
    @include breakpoint(small) {
      display: none;
    }
    @include breakpoint(large) {
      display: block;
    }
  }
  .title-bg-left {    
    background-image: url("/assets/images/title-lines-left-bg-repeat.png");
    left: 0;    
  }
  .title-bg-right {    
    background-image: url("/assets/images/title-lines-right-bg-repeat.png");
    left: 50vw;
  }

  .title-content {
    background-color: $primary-color;
    display: inline-block;    
    position: relative;
    @include breakpoint(small) {
      padding: rem-calc(32) rem-calc(14);
    }
    @include breakpoint(medium) {
      padding: rem-calc(48) rem-calc(64);
    }
    @include breakpoint(large) {
      padding: rem-calc(48) 0;
    }
    h2 {      
      color: $white;
      font-weight: bold;
      margin: 0;
      position: relative;
      z-index: 1;
    }
    .title-lines-left, .title-lines-right {
      background-size: contain;
      background-repeat: no-repeat;
      height: 100%;
      position: absolute;
      top: 0;      
      @include breakpoint(small) {
        width: 50%;
      }
      @include breakpoint(large) {
        width: 100%;
      }
    }
    .title-lines-left {
      background-image: url("/assets/images/title-lines-left.png");
      @include breakpoint(small) {
        background-position: top right;
        right:90%;
      }
      @include breakpoint(large) {
        background-position: center right;
        right:100%;
      }
    }
    .title-lines-right {
      background-image: url("/assets/images/title-lines-right.png");
      @include breakpoint(small) {
        background-position: bottom left;
        left:90%;
        width: 75%;
      }
      @include breakpoint(large) {
        background-position: center left;
        left:100%;
        width: 100%;
      }
    }
  }
}

// expand item
.expand-item {  
  border-radius: 0 0 rem-calc(10) rem-calc(10);
  margin-bottom: rem-calc(30);
  overflow: hidden;
  position: relative;

  .expand-top {
    background-color: $role-color;
    border-radius: 0 0 rem-calc(10) rem-calc(10);
    padding: rem-calc(28);
    position: relative;
    h2 {
      margin-bottom: rem-calc(16);
      text-transform: uppercase;
    }
    h3 {
      margin-bottom: rem-calc(20);
    }
    .role-logo {
      margin-left: rem-calc(16);
      max-width: rem-calc(144);
    }
  }
  .show-more {
    cursor: pointer;
    padding-left: rem-calc(32);
    position:relative;
    .expand-icon {
      background-color: transparent;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint(small) {
        height: rem-calc(20);
        left: 0;        
        width: rem-calc(20);
      }
      @include breakpoint(medium) {

      }
      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: $black;
      }
      &:before {
        bottom: rem-calc(8);
        left: 50%;
        top: rem-calc(8);
        transform: translateX(-50%);
        width: 100%;
      }
      &:after {
        top: 50%;
        left: rem-calc(8);
        right: rem-calc(8);
        height: 100%;
        transform: translateY(-50%);
      }
    }
    .expand-text {
      font-weight: bold;
    }
    &.is-active {
      .expand-icon {
        &:after {
          display: none;
        }
      }
    }
  }

  .additional-content {
    display: none;
  }
}