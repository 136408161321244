// role item
.role-item {  
  border-radius: 0 0 rem-calc(10) rem-calc(10);
  margin-bottom: rem-calc(30);
  overflow: hidden;
  position: relative;

  .role-top {
    background-color: $role-color;
    padding: rem-calc(28);
    position: relative;
    h2 {
      margin-bottom: rem-calc(16);
      text-transform: uppercase;
    }
    h3 {
      margin-bottom: rem-calc(20);
    }
    .role-logo {
      margin-left: rem-calc(16);
      max-width: rem-calc(144);
    }
  }
  .role-bottom {
    background-color: $dark-gray;
    border-radius: 0 0 rem-calc(10) rem-calc(10);
    position: relative;    
    @include breakpoint(small) {          
      padding: rem-calc(20);
    }
    @include breakpoint(large) {          
      padding: rem-calc(28);
    }
    .cell-relative {
      padding-bottom: rem-calc(60);
    }
    p {
      color: $white;
    }
    .btn-text {
      margin: 0 auto rem-calc(16) auto;      
      text-align: center;
      span {
        display: block;
        font-weight: bold;
        margin-top: rem-calc(10);
      }
      @include breakpoint(small) {          
        position: relative; 
      }
      @include breakpoint(large) {     
        bottom: rem-calc(60);
        left: rem-calc(5); 
        position: absolute; 
        right: rem-calc(5);
      }
    }
    .btn {
      bottom: rem-calc(20);
      left:0;
      margin: 0 auto;
      max-width: rem-calc(250);
      position: absolute;
      right:0;
    }
    .role-msg {
      bottom: rem-calc(32);
      font-weight: bold;
      left: rem-calc(16);
      margin: 0 auto;
      position: absolute;
      right: rem-calc(16);
      text-align: center;
    }
    &.role-temp-btn {
      padding: rem-calc(20)!important;
      text-align: center;
      .btn {
        position: relative;
        display: inline-block;
        bottom: initial;
      }
    }
  }
}