// our paths - list
.our-paths-item {
  background-color: $primary-color;
  margin-bottom: rem-calc(80);
  position: relative;
  &:nth-child(2n){
    background-color: $dark-gray;
  }
  .our-paths-item-image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;    
  }
  .our-paths-bottom {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;    
    justify-content: center;    
    padding: rem-calc(24) rem-calc(52) rem-calc(40) rem-calc(52);
    text-align: center;
    h3 {
      color: $white;
      
      font-weight: bold;
      text-transform: uppercase;
      @include breakpoint(small){
        font-size: rem-calc(20);
      }
      @include breakpoint(medium){
        font-size: rem-calc(24);
      }
    }
    .btn-paths {
      bottom: rem-calc(-16);
      left: 0;
      margin: 0 auto;
      position: absolute;
      right: 0;      
    }
  }
}