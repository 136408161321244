// testimonials

.testimonial-block {
  border: rem-calc(6) solid $primary-color;
  position: relative;
  .testimonial-details {
    background-color: $primary-color;
    color: $white;    
    text-align: center;
    @include breakpoint(large) {
      max-width: rem-calc(336);
      &:before {
        background-color: $primary-color;
        content: '';
        height: 100%;
        left:0;
        max-width: rem-calc(336);
        position: absolute;
        top:0;
        width: 100%;
        z-index: -1;
      }
    }

    .testimonial-name, .testimonial-role {      
      padding: rem-calc(20);
      p {
        color: $white;
        margin: 0;
      }
    }
    .testimonial-name {
      p {
        font-weight: bold;
        @include breakpoint(small){
          font-size: rem-calc(26);
          line-height: rem-calc(26);
      }
      @include breakpoint(medium){
          font-size: rem-calc(30);
          line-height: rem-calc(32);
      }
      }
    }
    .testimonial-role {
      p {
        font-size: rem-calc(20);
        line-height: rem-calc(26);
      }
    }
    .testimonial-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      max-width: rem-calc(336);

    }
  }
  .testimonial-content {    
    @include breakpoint(small) {
      padding: rem-calc(32);
    }
    @include breakpoint(large) {
      padding: rem-calc(10) rem-calc(80) rem-calc(10) rem-calc(40);
    }
    p {
      @extend .section-title-small;
    }
  }
  .testimonial-corner {
    bottom:0;
    position: absolute;
    right:0;
    background-image: url('/assets/images/testimonial-corner.png');
    background-size: cover;
    @include breakpoint(small) {
      width: rem-calc(54);
      height: rem-calc(54);
    }
    @include breakpoint(large) {
      width: rem-calc(112);
      height: rem-calc(112);
    }
  }
}