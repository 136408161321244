// typography

h1,h2,h3,h4,h5,h6{
	font-weight: 400;
}

.light{
	font-weight: 300!important;
}
.regular{
	font-weight: 400!important;
}
.medium{
	font-weight: 500!important;
}
.bold{
	font-weight: 700!important;
}
.underline{
	text-decoration: underline;
}
.uppercase {
    text-transform: uppercase;
}

p, li{
    color: $black;
    @include breakpoint(small){
        font-size: rem-calc(16);
        line-height: rem-calc(20);
    }
    @include breakpoint(large){
        font-size: rem-calc(19);
        line-height: rem-calc(26);
    }           
	a{
		text-decoration: underline;
	}
}

.section-title-xlarge {
    @include breakpoint(small){
        font-size: rem-calc(32);
        line-height: rem-calc(32);
    }
    @include breakpoint(large){              
        font-size: rem-calc(50);        
        line-height: rem-calc(60);
    }
}

.section-title-large {
    @include breakpoint(small){
        font-size: rem-calc(28);
        line-height: rem-calc(28);
    }
    @include breakpoint(medium){
        font-size: rem-calc(40);
        line-height: rem-calc(40);
    }
}

.section-title-medium {
    @include breakpoint(small){
        font-size: rem-calc(24);
        line-height: rem-calc(24);
    }
    @include breakpoint(medium){
        font-size: rem-calc(36);
        line-height: rem-calc(36);
    }
}

.section-title-small { 
    @include breakpoint(small){
        font-size: rem-calc(20);
        line-height: rem-calc(24);
    }
    @include breakpoint(medium){
        font-size: rem-calc(26);
        line-height: rem-calc(32);
    }
}

.section-title-xsmall { 
    @include breakpoint(small){
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }
    @include breakpoint(medium){
        font-size: rem-calc(20);
        line-height: rem-calc(28);
    }
}