footer {
  background: $primary-color;
  color: $white;  
  padding: rem-calc(20) 0;
  position: relative;
  text-align: left;
  .grid-relative {
    z-index: 1;
  }
  .footer-logo {
    @include breakpoint(small) {
      text-align: center;
    }
    @include breakpoint(large) {
      text-align: left;
    }
    img {
      max-width: rem-calc(170);
      @include breakpoint(small) {
        margin-bottom: rem-calc(32);
      }
      @include breakpoint(large) {
        margin-bottom: 0;
      }
    }

  }
  .footer-eed-logo {
    max-width: rem-calc(314);
    width: 100%;
  }
  .social-links-container {        
    @include breakpoint(small) {
      margin-top: rem-calc(32);
      text-align: center;
    }
    @include breakpoint(large) {
      margin-top: 0;
      max-width: rem-calc(380);
      text-align: left;
    }
  }
  .social-text {
    color: $white;
    @include breakpoint(small) {
      font-size: rem-calc(22);
      line-height: rem-calc(22);
    }
    @include breakpoint(medium) {
      font-size: rem-calc(25);
      line-height: rem-calc(25);
    }    
  }
  .social-links {
    display: flex;
    flex-wrap: wrap;  
    list-style: none;
    margin: 0 auto;  
    @include breakpoint(small) {
      justify-content: center; 
    } 
    @include breakpoint(large) {
      justify-content: right; 
    }  
    li {
      .social-icon {
        align-items: center;
        background-color: $white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: flex;
        justify-content: center;
        margin: rem-calc(6) rem-calc(10);
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: contain;
        mask-size: contain;
        @include breakpoint(small) {
          height: rem-calc(32);
          width: rem-calc(32);            
        }
        @include breakpoint(large) {
          height: rem-calc(48);
          width: rem-calc(48);            
        }
        &:hover {
          opacity: 0.9;
        }
        &.facebook {
          -webkit-mask-image: url('/assets/images/social-facebook.svg');
          mask-image: url('/assets/images/social-facebook.svg');
          &:focus {
            background-image: url('/assets/images/social-facebook.svg');
          }
        }
        &.instagram {
          -webkit-mask-image: url('/assets/images/social-instagram.svg');
          mask-image: url('/assets/images/social-instagram.svg');
          &:focus {
            background-image: url('/assets/images/social-instagram.svg');
          }
        }
        &.linkedin {
          -webkit-mask-image: url('/assets/images/social-linkedin.svg');
          mask-image: url('/assets/images/social-linkedin.svg');
          &:focus {
            background-image: url('/assets/images/social-linkedin.svg');
          }
        }
        &.tiktok {
          -webkit-mask-image: url('/assets/images/social-tiktok.svg');
          mask-image: url('/assets/images/social-tiktok.svg');
          &:focus {
            background-image: url('/assets/images/social-tiktok.svg');
          }
        }
        &.twitter {
          -webkit-mask-image: url('/assets/images/social-twitter.svg');
          mask-image: url('/assets/images/social-twitter.svg');
          &:focus {
            background-image: url('/assets/images/social-twitter.svg');
          }
        }
        &.vimeo {
          -webkit-mask-image: url('/assets/images/social-vimeo.svg');
          mask-image: url('/assets/images/social-vimeo.svg');
          &:focus {
            background-image: url('/assets/images/social-vimeo.svg');
          }
        }
        &.youtube {
          -webkit-mask-image: url('/assets/images/social-youtube.svg');
          mask-image: url('/assets/images/social-youtube.svg');
          &:focus {
            background-image: url('/assets/images/social-youtube.svg');
          }
        }
        &:focus {
          -webkit-mask-image: none;
          mask-image: none;
        }
      }
      &:first-of-type {
        .social-icon {
          margin-left: 0;
        }
      }
      &:last-of-type {
        .social-icon {
          margin-right: 0;
        }
      }
    }
  }  
  .footer-links {
    display: flex;
    justify-content: center;
    list-style: none;
    @include breakpoint(small) {
      flex-direction: column;
      margin: rem-calc(30) 0;
    }
    @include breakpoint(medium) {
      flex-direction: row;
    }
    li {
      display: block;
      @include breakpoint(small) {
        margin: rem-calc(8) 0;
      }
      @include breakpoint(medium) {
        margin: 0;
      }
      a {
        color: $white;
        display: inline-block;
        font-size: rem-calc(18);
        line-height: rem-calc(25);
        margin: 0 rem-calc(32);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
