@import 'foundation';
@import '~foundation-sites/scss/util/util';
@import '~foundation-sites/scss/util/mixins';

@import './components/blocks';
@import './components/buttons';
@import './components/content';
@import './components/footer';
@import './components/header';
@import './components/hero';
@import './components/our-paths';
@import './components/reveal';
@import './components/roles';
@import './components/tabs';
@import './components/testimonials';
@import './components/typography';
@import './components/video-block';
@import './functions';


@include padding-list();
@include margin-list();

#skip-to-content {
	padding: 1em 2em;
	position: absolute;
	top: -100%;
	left: 0		;
	width: 100%;
	z-index: 1000;
	&:focus {
		display: block;
	  position: static;
	  top: 0;
	  left:0;
	  background: $focus-color;
	}  
}

a:focus, button:focus {
	outline: 1px solid transparent!important;
	background-color: $focus-color!important;
	color: $black!important;	
	box-shadow: 0 0 $focus-color, 0 4px $black!important;
}

main{	
	overflow: hidden;
	position: relative;
	&:focus {
		outline: 1px solid transparent!important;
	}
}

// grid settings
.grid-relative, .cell-relative{
	position: relative;
}

// overflow cell
.container-overflow {
	overflow: hidden;
}

// transition all
.transition-all{
	transition: all 0.25s ease;
}

// old browsers
.old-browsers {
	position: fixed;
	width:100%;
	height:100vh;
	background-color: $white;
	text-align: center;
	z-index: 10000;
	padding:100px 0; 
	p {
		width:100%;
		max-width: 640px;
		margin: 20px auto;
	}
	ul {
		list-style: none;
		margin: 20px 0;
		li {
			display: inline-block;
			margin: 20px;
			a {
				display: block;
			}
		}
	}
}