//hero
.hero-bg {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .hero-bg-left, .hero-bg-right {
    background: $primary-color;
    mix-blend-mode: multiply;
    position: absolute;    
    transform: rotate(135deg) scaleX(-1);
    z-index: 1;
    &:before {
      content: '';
      display: block;
      width:100%;
      height: 100%;
      background-color: rgba(0,0,0,0.15);
    }
  }
  .hero-bg-left {
    @include breakpoint(small) {
      right: 8vw;
      width: 150vw;
      height: 120vw;
    }
    @include breakpoint(medium) {
      right: 8vw;
      width: 125vw;
      height: 100vw;
    }
    @include breakpoint(large) {
      right: 30vw;
      width: 125vw;
      height: 100vw;
    }
    position: absolute;
    top: -50vw;
  }
  .hero-bg-right {
    @include breakpoint(small) {
      display: none;
    }
    @include breakpoint(large) {
      display: block;
      bottom:-130px;
      right:-130px;
      width: 260px;
      height: 260px;
    }
  }
  .hero-content {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    @include breakpoint(small) {
      padding: rem-calc(60) 0;
      max-width: rem-calc(220);
      min-height: rem-calc(220);
    }
    @include breakpoint(medium) {
      padding: rem-calc(80) 0;
      max-width: rem-calc(320);
      min-height: rem-calc(320);
    }
    @include breakpoint(large) {
      padding: rem-calc(80) 0;
      max-width: rem-calc(442);
      min-height: rem-calc(420);
    }
    h1 {
      color: $white;      
      @include breakpoint(small){
        font-size: rem-calc(32);
        line-height: rem-calc(32);
      }
      @include breakpoint(medium){
        font-size: rem-calc(54);
        line-height: rem-calc(54);
      }
      @include breakpoint(large){              
          font-size: rem-calc(70);        
          line-height: rem-calc(70);
      }
    }
    p {
      color: $white;
      margin-bottom: 0;      
      @include breakpoint(small){
        font-size: rem-calc(20);
        line-height: rem-calc(20);
        margin-top: rem-calc(24);
      }
      @include breakpoint(medium){
        font-size: rem-calc(24);
        line-height: rem-calc(24);
        margin-top: rem-calc(32);
      }
      @include breakpoint(large){
        font-size: rem-calc(40);
        line-height: rem-calc(40);
        margin-top: rem-calc(32);
      }
    }    
  }
}